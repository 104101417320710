<template>
    <div class="page">

<!--        <img class="app-icon" src="@/assets/img/app_icon.png"-->
<!--             style="">-->

        <div style="margin: 0 30px">


            <div @click="showSelectCountry=true">
            <a-input
                    class="input-item"
                    :value="countryText"
                    readOnly
                    size="large"
                    placeholder="Country / Region">
                <a-icon slot="prefix"
                        type="global"
                />
            </a-input>
            </div>

            <a-input
                    class="input-item"
                    v-model="username"
                    size="large"
                    allowClear
                    placeholder="Phone / Email">
                <a-icon slot="prefix"
                        type="user"
                />
            </a-input>

            <a-input-password
                    class="input-item"
                    placeholder="Password"
                    v-model="password"
                    size="large"
                    allowClear >
                <a-icon slot="prefix"
                        type="lock"
                />
            </a-input-password>


            <a-button type="primary"
                      html-type="submit"
                      size="large"
                      @click="login"
                      class="login-form-button w-100">
                Login
            </a-button>

        </div>

        <a-drawer
            title="Select country / region"
            :closable="true"
            placement="bottom"
            height="100%"
            :headerStyle="{position:'fixed',width:'100%',background:'#FFF','z-index':'100'}"
            @close="showSelectCountry=false"
            :visible="showSelectCountry">

            <a-input
                    class="input-item"
                    v-model="search"
                    size="large"
                    allowClear
                    style="margin-top: 50px"
                    >
                <a-icon slot="prefix"
                        type="search"
                />
            </a-input>

            <div>

                <a-list :data-source="countryList">
                    <a-list-item slot="renderItem" slot-scope="item" style="padding: 0">
                        <div class="country-item" @click="selectCountry(item)">
                            <div class="country-name">{{item.areaName}}</div>
                            <div class="country-code">+{{item.code}}</div>
                        </div>
                    </a-list-item>
                </a-list>

            </div>

        </a-drawer>

    </div>
</template>

<script>

    import axios from 'axios'

    import en from "./countries/en-US"

    export default {
        data() {
            return {
                showSelectCountry:false,
                selectedCountry:null,
                username: "",
                password: "",
                search:""
            }
        },
        methods: {
            selectCountry(country) {
                this.selectedCountry = country
                this.showSelectCountry = false
            },
            login() {

                if(!this.selectedCountry || !this.username || !this.password){
                    return
                }

                let paras = this.getUrlPara()
                let state = paras.state
                let redirectUri = paras.redirect_uri


                let data = {
                    "password": this.password,
                    "registration": this.selectedCountry.domainAbbreviation,
                    "account": this.username
                }
                let url = "https://dev.joyonway.com/spa-sass/app/auth/login"
                axios.post(url, data).then(res => {
                    console.log('res=>', res.data);
                    if(res.data.code==0){
                        let code = res.data.data.code
                        window.location.href = `${redirectUri}?state=${state}&code=${code}`
                    }else{
                        //登录失败
                    }

                })
            },

            getUrlPara :function () {
                var search = window.location.search
                var searchs = {}
                var strs = []
                if (search.indexOf('?') !== -1) {
                    search = search.substr(1)
                    strs = search.split('&')
                    for (var i = 0; i < strs.length; i++) {
                        searchs[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
                    }
                }
                return searchs
            }
        },

        computed:{
            countryText(){
                if(this.selectedCountry){
                    let name = this.selectedCountry.areaName
                    let code = this.selectedCountry.code
                    return `(+${code}) ${name}`
                } else {
                    return ''
                }
            },
            countryList(){
                if(this.search){
                    return en.filter(item=>{
                        return item.areaName.toUpperCase().indexOf(this.search.toUpperCase())>=0 ||
                                item.code.indexOf(this.search)>=0
                    })
                }else{
                    return en
                }

            }
        }
    }
</script>

<style scoped>
    .page {
        min-height: 100vh;
        background: #FAFAFA;
        text-align: center;
    }

    .app-icon {
        height: 70px;
        width: 70px;
        border-radius: 5px;
        margin: 30px;
    }

    .input-item {
        margin-bottom: 15px;
    }

    .country-item{
        display: flex;
        width: 100%;
        padding: 15px;
    }
    .country-item:active{
        background: #F0F0F0;
    }
    .country-name{
        flex-grow: 1;
    }
</style>
